<template>
    <div>
        <ul class="ping-ul">
            <router-link v-for="(m, index) in list" :to="{path:'/oridydes?id='+m.dy_id}">
                <li class="list">
                    <img :src="rImg(m.user.head_portrait)" :onerror="txImg" class="tx" alt="">
                    <div class="content">
                        <p class="name">{{m.user.name}}</p>
                        <p class="content ellipsis-2">{{m.content}}</p>
                        <div class="des-box">
                            <h3 class="title ellipsis-1" v-if="m.dy_title">{{m.dy_title}}</h3>
                            <p class="des ellipsis-2">{{clearHtml(m.dy_content)}}</p>
                        </div>
                    </div>
                </li>
            </router-link>
        </ul>
        <div class="page-box" v-show="total">
            <el-pagination :pager-count="5" :page-size="20" @current-change="changePage" background layout="prev, pager, next" :total="total"></el-pagination>
        </div>
    </div>
</template>

<script>
    import {
        mapGetters,
        mapMutations
    } from 'vuex';
    export default {
        components: {},
        data() {
            return {
                list: [],
                total: 0,
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
        },
        mounted() {
            // 在组件被挂载之后调用。
            this.getData(1)
        },
        methods: {
            // ...mapMutations([])
            changePage(s) {
                this.getData(s);
            },
            // ...mapMutations([])
            // 获取列表
            async getData(page) {
                let param = {
                    page: page || 1,
                };
                let {
                    list,
                    total
                } = await this.$y_list("api/Dymanic/commentList", [], param);
                this.list = list;
                if (total != -1) {
                    this.total = total;
                }
            },
        },
        computed: {
            // 计算属性
            // ...mapGetters([])
        },
        watch: {
            // 监听
        }
    };
</script>

<style lang="less" scoped>
    .list {
        display: flex;
        padding: 15px;
        border-bottom: 1px solid #eee;

        .tx {
            width: 50px;
            height: 50px;
            margin-right: 20px
        }

        .name {
            font-size: 20px;
            margin-bottom: 7px;
        }

        .des-box {
            margin-top: 7px;
            padding: 10px;
            background: #f3f3f3;
            border-radius: 7px;
            color: #555;

            .title {
                margin-bottom: 7px;
            }

            .des {
                color: #999;
                font-size: 12px;
            }
        }

    }
</style>